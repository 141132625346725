import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import variousDeviceMockup from './various_device_mockup.png';
import macbookCalendar from './macbook_calendar.png';
import phoneProduct from './phone_product.png';
import payment from './payment.jpg';
import contact from './contact.png';
import { Button, Card, List, Modal, Space, Typography, Table } from 'antd';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const Container = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #333;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
`;

const Section = styled.section<{ bgColor: string; isCentered: boolean; bgImage?: string }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  scroll-snap-align: start;
  padding: 2rem;
  background-color: ${props => props.bgColor};

  @media (min-width: 768px) {
    flex-direction: ${props => props.isCentered ? 'column' : 'row'};
  }
`;

const Content = styled.div<{ isCentered: boolean }>`
  text-align: center;
  z-index: 1;
  animation: ${fadeIn} 1s ease-in;
  max-width: 100%;
  position: relative;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    text-align: ${props => props.isCentered ? 'center' : 'left'};
    max-width: ${props => props.isCentered ? '80%' : '50%'};
    ${props => !props.isCentered && `
      margin-right: 2rem;
    `}
  }
`;

const ImagePlaceholder = styled.div<{ isCentered: boolean }>`
  width: 100%;
  height: 40vh;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: 768px) {
    width: ${props => props.isCentered ? '80%' : '40%'};
    height: ${props => props.isCentered ? '50vh' : '60vh'};
    margin-left: 2rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  animation: ${slideUp} 1s ease-out;
  color: #ffffff;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    font-size: 3.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  max-width: 100%;
  margin: 0 auto 2rem;
  animation: ${slideUp} 1s ease-out 0.2s both;
  line-height: 1.6;
  color: #ffffff;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    font-size: 1.8rem;
    max-width: 700px;
  }
`;

const CTAButton = styled.button`
  background-color: #ffffff;
  color: #333;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.4rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
  animation: ${slideUp} 1s ease-out 0.4s both;
  position: relative;
  z-index: 2;

  &:hover {
    background-color: #f0f0f0;
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.3);
  }
`;

const NavBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 113, 227, 0.95);
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  height: 50px;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
    height: auto;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const LogoSVG = styled.svg`
  width: 30px;
  height: 30px;
  margin-right: 5px;

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;

const NavLinks = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
  padding: 0.5rem 0;
  font-size: 1.1rem;

  &:hover {
    color: #f0f0f0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }
`;

const NavButton = styled.button`
  background-color: #ffffff;
  color: #0071e3;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  }

  @media (min-width: 768px) {
    padding: 0.7rem 1.5rem;
    font-size: 1.1rem;
  }
`;

const MobileMenuButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background-color: rgba(0, 113, 227, 0.95);
  padding: 1rem;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 999;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Footer = styled.footer`
  background-color: #f8f9fa;
  color: #333;
  padding: 1rem 0;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0;
    gap: 1.5rem;
  }
`;

const FooterLogo = styled.div`
  font-size: 1.0rem;
  font-weight: bold;
  color: #0071e3;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

// const FooterLinks = styled.div`
//   display: flex;
//   gap: 1rem;
//   flex-wrap: wrap;
//   justify-content: center;
//
//   @media (min-width: 768px) {
//     gap: 2rem;
//   }
// `;
//
// const FooterLink = styled.a`
//   color: #333;
//   text-decoration: none;
//   transition: color 0.3s ease;
//   font-size: 0.7rem;
//
//   &:hover {
//     color: #0071e3;
//   }
//
//   @media (min-width: 768px) {
//     font-size: 1.1rem;
//   }
// `;

const Copyright = styled.p`
  margin-top: 1rem;
  font-size: 0.8rem;

  @media (min-width: 768px) {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;
  text-align: left;
  position: relative;
  z-index: 2;
`;

const FeatureItem = styled.li`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: #ffffff;

  &:before {
    content: '✓';
    color: #ffffff;
    font-weight: bold;
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CurlyLine = styled.div`
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  height: 100px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23ffffff' fill-opacity='1' d='M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,213.3C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  z-index: 2;
`;

const HomePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const sectionRefs = useRef<(HTMLElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.title = "YY Tech";
  }, []);
  
  const sections = [
    {
      id: "home",
      title: "Revolutionize Your Salon Management",
      description: "Streamline bookings, boost efficiency, and grow your business with YYSchedule - the all-in-one solution for modern salons.",
      background: "rgb(186, 203, 202)",
      bgImage: variousDeviceMockup,
      features: [
        "Easy online booking for clients",
        "Automated appointment reminders",
        "Staff scheduling and management",
        "Inventory tracking and management"
      ],
      isCentered: false
    },
    {
      id: "features",
      title: "Powerful Real-Time Calendar",
      description: "Stay on top of your business with our intuitive, real-time calendar. Manage appointments, staff schedules, and resources all in one place.",
      background: "#50E3C2",
      bgImage: macbookCalendar,
      features: [
        "Drag-and-drop appointment scheduling",
        "Color-coded calendar for easy viewing",
        "Multi-location support",
      ],
      isCentered: false
    },
    {
      title: "Customizable Booking Page",
      description: "Create a professional online presence with our free, fully customizable booking page. Let clients book appointments 24/7, even when you're busy or closed.",
      background: "#F5A623",
      bgImage: phoneProduct,
      features: [
        "Mobile-friendly design",
        "Customizable colors and branding",
        "Service menu with prices and duration",
        "Integration with your existing website"
      ],
      isCentered: false
    },
    {
      title: "Secure Payment Processing",
      description: "Reduce no-shows and secure your revenue with our integrated payment system. Accept deposits, prepayments, and process transactions securely.",
      background: "#FF6B6B",
      bgImage: payment,
      features: [
        "Accept credit cards and digital wallets",
        "Set up recurring payments for memberships",
        "Customizable cancellation policies",
        "Detailed financial reporting"
      ],
      isCentered: false
    },
    {
      id: "pricing",
      title: "Unbeatable Value for Your Business",
      description: "Experience premium features at an unmatched price. Our commitment to affordability ensures businesses of all sizes can thrive with our powerful scheduling solution.",
      background: "#9013FE",
      features: [],
      isCentered: true
    },
    {
      id: "contact",
      title: "Get in Touch",
      description: "Have questions or need assistance? We're here to help! Reach out to us through any of the following channels:",
      background: "#4A90E2",
      bgImage: contact,
      features: [
        "Email: support@yytech.me",
        "WeChat: YYTechSupport(Coming soon)",
        "Instagram: @YYTech(Coming soon)"
      ],
      isCentered: false
    }
  ];

  const pricingColumns = [
    {
      title: 'Feature',
      dataIndex: 'feature',
      key: 'feature',
    },
    {
      title: 'YYSchedule',
      dataIndex: 'yySchedule',
      key: 'yySchedule',
    },
    {
      title: 'Competitors',
      dataIndex: 'competitors',
      key: 'competitors',
    },
  ];

  const pricingData = [
    {
      key: '1',
      feature: 'Monthly Price',
      yySchedule: '$39.99',
      competitors: '$99 (average)',
    },
    {
      key: '2',
      feature: 'Employee Limit',
      yySchedule: 'Unlimited',
      competitors: 'Extra charge per employee',
    },
    {
      key: '3',
      feature: 'Contract Length',
      yySchedule: 'No commitment, cancel anytime',
      competitors: 'Often requires long-term contract',
    },
    {
      key: '4',
      feature: 'Pricing Transparency',
      yySchedule: 'Fully transparent, no hidden fees',
      competitors: 'Often has hidden fees',
    },
    {
      key: '5',
      feature: 'Customizable Homepage',
      yySchedule: 'Included for free',
      competitors: 'None',
    },
    {
      key: '6',
      feature: 'Free Trial',
      yySchedule: '1 month free',
      competitors: 'Limited or no free trial',
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollPosition = containerRef.current.scrollTop;
        const windowHeight = window.innerHeight;

        sectionRefs.current.forEach((section, index) => {
          if (section) {
            const sectionTop = section.offsetTop;
            if (scrollPosition >= sectionTop - windowHeight / 2) {
              setActiveSection(index);
            }
          }
        });
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const scrollToSection = (index: number) => {
      const section = sectionRefs.current[index];
      if (section && containerRef.current) {
        containerRef.current.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth'
        });
      }
    };

    const timer = setTimeout(() => {
      const nextSection = (activeSection + 1) % sections.length;
      scrollToSection(nextSection);
    }, 8000);

    return () => clearTimeout(timer);
  }, [activeSection, sections.length]);

  const scrollToSection = (id: string) => {
    const sectionIndex = sections.findIndex(section => section.id === id);
    if (sectionIndex !== -1) {
      const section = sectionRefs.current[sectionIndex];
      if (section && containerRef.current) {
        containerRef.current.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <>
      <GlobalStyle />
      <NavBar>
        <Logo>
          <LogoSVG viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45" fill="#4A90E2" />
            <text x="50" y="65" fontFamily="Arial" fontSize="50" fill="white" textAnchor="middle">YY</text>
          </LogoSVG>
          YYTech
        </Logo>
        <MobileMenuButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          ☰
        </MobileMenuButton>
        <NavLinks>
          <NavLink href="#home" onClick={(e) => { e.preventDefault(); scrollToSection('home'); }}>Home</NavLink>
          <NavLink href="#features" onClick={(e) => { e.preventDefault(); scrollToSection('features'); }}>Features</NavLink>
          <NavLink href="#pricing" onClick={(e) => { e.preventDefault(); scrollToSection('pricing'); }}>Pricing</NavLink>
          <NavLink href="#contact" onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }}>Contact</NavLink>
          <NavButton onClick={() => setShowModal(true)}>Start Free Trial</NavButton>
        </NavLinks>
        <MobileMenu isOpen={mobileMenuOpen}>
          <NavLink href="#home" onClick={(e) => { e.preventDefault(); scrollToSection('home'); setMobileMenuOpen(false); }}>Home</NavLink>
          <NavLink href="#features" onClick={(e) => { e.preventDefault(); scrollToSection('features'); setMobileMenuOpen(false); }}>Features</NavLink>
          <NavLink href="#pricing" onClick={(e) => { e.preventDefault(); scrollToSection('pricing'); setMobileMenuOpen(false); }}>Pricing</NavLink>
          <NavLink href="#contact" onClick={(e) => { e.preventDefault(); scrollToSection('contact'); setMobileMenuOpen(false); }}>Contact</NavLink>
          <NavButton onClick={() => { setShowModal(true); setMobileMenuOpen(false); }}>Start Free Trial</NavButton>
        </MobileMenu>
      </NavBar>
      <Container ref={containerRef}>
        {sections.map((section, index) => (
          <Section
            key={index}
            ref={el => sectionRefs.current[index] = el}
            bgColor={section.background}
            isCentered={section.isCentered}
            bgImage={section.bgImage}
          >
            <Content isCentered={section.isCentered}>
              <Title>{section.title}</Title>
              <Description>{section.description}</Description>
              {section.id === 'pricing' ? (
                <Table
                  dataSource={pricingData}
                  columns={pricingColumns}
                  pagination={false}
                  bordered
                  style={{ marginTop: '2rem', backgroundColor: 'white' }}
                  rowClassName={(record, index) => index === 0 ? 'highlight-row' : ''}
                />
              ) : (
                <FeatureList>
                  {section.features.map((feature, featureIndex) => (
                    <FeatureItem key={featureIndex}>{feature}</FeatureItem>
                  ))}
                </FeatureList>
              )}
              {index === 0 && <CTAButton onClick={() => setShowModal(true)}>Start Your Free Trial</CTAButton>}
            </Content>
            {section.bgImage ? (
              <ImagePlaceholder isCentered={section.isCentered}>
                <img src={section.bgImage} alt={section.title} />
              </ImagePlaceholder>
            ) : (
              <></>
            )}
            {index < sections.length - 1 && <CurlyLine />}
          </Section>
        ))}
      </Container>
      {showModal && (
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
          width={600}
          centered
        >
          <Card
            title={<Typography.Title level={3}>Request Your Free Trial</Typography.Title>}
            bordered={false}
          >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Typography.Paragraph>
                Thank you for your interest in YYSchedule. We're excited to offer you a free trial of our comprehensive scheduling solution.
              </Typography.Paragraph>
              <Typography.Paragraph>
                To get started, please send an email to <Typography.Text strong>support@yytech.me</Typography.Text> with the following information:
              </Typography.Paragraph>
              <List
                size="small"
                bordered
                dataSource={[
                  'Your full name',
                  'Business name',
                  'Contact phone number',
                  'Brief description of your business'
                ]}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
              <Typography.Paragraph>
                Our team will review your request and set up your free trial account within one business day. We look forward to helping you streamline your scheduling process and grow your business.
              </Typography.Paragraph>
              <Button type="primary" onClick={() => setShowModal(false)} block>
                Close
              </Button>
            </Space>
          </Card>
        </Modal>
      )}
      <Footer>
        <FooterContent>
          <FooterLogo>YY Tech Inc.</FooterLogo>
          <Copyright>&copy; 2024 YY Tech Inc. All rights reserved.</Copyright>
        </FooterContent>
      </Footer>
    </>
  );
};

export default HomePage;