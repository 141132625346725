import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import HomePage from "./apps/BusinessHomePage/HomePage";

// Set favicon
const setFavicon = () => {
  let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    document.head.appendChild(link);
  }
  link.type = 'image/svg+xml';
  link.rel = 'shortcut icon';
  link.href = '/logo.svg';
};

// Set favicon and title
setFavicon();
document.title = 'YY Tech';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/*<App />*/}
    {<HomePage/>}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
